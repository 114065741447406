import { BoundingBoxType } from '../../services/constants/constants';
import { BoundingBoxActionTypes } from '../constants/boundingBoxActionTypes';

// State Structure for Draw Bounding Box feature
// States used -: activeBboxType, resizableBboxes, modifiedBboxes, ocrData
// Structure -:
//   resizableBboxes, modifiedBboxes -:
//   [
//     {
//       pageNumber: 1,
//       bboxes: [
//         {
//           id: "", // in case of draw new this will be UI generated else it will id from DB
//           type: "table | row | column | cell",
//           coordinates: [0,0,0,0],
//           gridlines: [
//             {
//               id: "",
//               type: "vertical | horizontal",
//               offset: 100, // if vertical then offset is from left coord else from top coord
//               order: 1, // starts from 1
//             }
//           ]
//         }
//       ]
//     }
//   ]

//   ocrData -:
// [
//   {
//     id: '', // this can be tableId, cellId, rowId, etc.
//     ocrData: [
//       {
//         id: "",
//         text: "",
//       }
//     ]
//   }
// ]

const initialState = {
  activeDocumentIndex: 0,
  bbCoordinate: null,
  allBoundingBoxes: [],
  showAllBB: [],
  annotations: [],
  activeBboxType: BoundingBoxType.NONE,
  resizableBboxes: [],
  modifiedBboxes: [],
  ocrData: [],
};

const boundingBoxReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BoundingBoxActionTypes.SET_ACTIVE_DOCUMENT_INDEX:
      return { ...state, activeDocumentIndex: payload };

    case BoundingBoxActionTypes.SET_BBOX:
      return { ...state, bbCoordinate: payload };

    case BoundingBoxActionTypes.RESET_BBOX:
      return { ...state, bbCoordinate: null };

    case BoundingBoxActionTypes.SET_ALL_BBOX:
      return { ...state, allBoundingBoxes: payload };

    case BoundingBoxActionTypes.RESET_ALL_BBOX:
      return { ...state, allBoundingBoxes: [] };

    case BoundingBoxActionTypes.SET_SHOW_ALL_BBOX:
      return { ...state, showAllBB: payload };

    case BoundingBoxActionTypes.SET_ANNOTATIONS:
      return { ...state, annotations: payload };

    case BoundingBoxActionTypes.RESET_ANNOTATIONS:
      return { ...state, annotations: [] };

    case BoundingBoxActionTypes.SET_ACTIVE_BBOX_TYPE:
      return { ...state, activeBboxType: payload };

    case BoundingBoxActionTypes.SET_RESIZABLE_BBOXES:
      return { ...state, resizableBboxes: payload };

    case BoundingBoxActionTypes.SET_MODIFIED_BBOXES:
      return { ...state, modifiedBboxes: payload };

    case BoundingBoxActionTypes.SET_OCR_DATA:
      return { ...state, ocrData: payload };

    case BoundingBoxActionTypes.RESET_BOUNDING_BOX:
      return initialState;

    default:
      return state;
  }
};

export default boundingBoxReducer;
