import { BoundingBoxActionTypes } from '../constants/boundingBoxActionTypes';

export const setActiveDocumentIndex = (index) => ({
  type: BoundingBoxActionTypes.SET_ACTIVE_DOCUMENT_INDEX,
  payload: index,
});

export const setBbox = (bbox) => ({
  type: BoundingBoxActionTypes.SET_BBOX,
  payload: bbox,
});

export const resetBbox = () => ({
  type: BoundingBoxActionTypes.RESET_BBOX,
});

export const setAllBbox = (allBbox) => ({
  type: BoundingBoxActionTypes.SET_ALL_BBOX,
  payload: allBbox,
});

export const resetAllBbox = () => ({
  type: BoundingBoxActionTypes.RESET_ALL_BBOX,
});

export const setShowAllBbox = (value) => ({
  type: BoundingBoxActionTypes.SET_SHOW_ALL_BBOX,
  payload: value,
});

export const setAnnotations = (annotations) => ({
  type: BoundingBoxActionTypes.SET_ANNOTATIONS,
  payload: annotations,
});

export const resetAnnotations = () => ({
  type: BoundingBoxActionTypes.RESET_ANNOTATIONS,
});

export const resetBoundingBoxState = () => ({
  type: BoundingBoxActionTypes.RESET_BOUNDING_BOX,
});

export const setActiveBboxType = (type) => ({
  type: BoundingBoxActionTypes.SET_ACTIVE_BBOX_TYPE,
  payload: type,
});

export const setResizableBboxes = (bboxes) => ({
  type: BoundingBoxActionTypes.SET_RESIZABLE_BBOXES,
  payload: bboxes,
});

export const setModifiedBboxes = (bboxes) => ({
  type: BoundingBoxActionTypes.SET_MODIFIED_BBOXES,
  payload: bboxes,
});

export const setOcrData = (data) => ({
  type: BoundingBoxActionTypes.SET_OCR_DATA,
  payload: data,
});
