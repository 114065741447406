// importing libraries
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowTabs from '../workflow-tabs/WorkflowTabs';
// importing styles
import classes from './WorkflowHeader.module.css';
import IconButton from '../icon-button/IconButton';
import DociphiLogo from '../../../assets/DociphiLogo';
import Timer from '../timer/Timer';
import useAxios from '../../../hooks/useAxios';
import { useSnackbar } from '../../../context/SnackbarContext';
import { ApplicationService } from '../../../services/constants/endpoints';
import { removeOpenedApplication, setOpenedApplication } from '../../../redux/actions/applicationActions';
import CustomizedTooltip from '../../atoms/Tooltip/CustomizedTooltip';

function WorkflowHeader() {
  const axiosPrivate = useAxios();
  const { openSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const { description } = useSelector((state) => state.applications.openedApplication);

  const fetchApplication = () => {
    axiosPrivate.get(`${ApplicationService.GET_ATTACHMENT(applicationId)}`)
      .then((response) => {
        dispatch(setOpenedApplication({
          documents: response.data.data.documents,
          description: response.data.data.description,
          review_time: response.data.data.review_time,
          status: response.data.data.status,
          allDocumentsLoaded: true,
          assignment: response.data.data.assignment,
          typeOfBusiness: response.data.data.type_of_business,
        }));
      })
      .catch((err) => {
        openSnackbar('error', err?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchApplication();

    return () => {
      dispatch(removeOpenedApplication());
    };
  }, []);

  const goToDashboard = () => {
    navigate('/home/application-dashboard');
  };

  return (
    <>
      <div className={classes.workflowHeaderContainer}>
        <div className={classes.headerLeftPart}>
          <IconButton
            iconName="arrowBack"
            iconHeight={11}
            iconWidth={10}
            iconColor="black"
            handleClick={goToDashboard}
          />
          <p className={`h3Medium ${classes.reviewingStyle}`}>Reviewing: </p>
          <CustomizedTooltip text={description} position="bottom">
            <p className={`h3Regular ${classes.appDesc}`}>{description}</p>
          </CustomizedTooltip>
        </div>
        <div className={classes.headerRightPart}>
          <Timer applicationId={applicationId} fetchApplication={fetchApplication} />
          <div className={classes.dociphiLogo}>
            <DociphiLogo />
          </div>
        </div>
      </div>
      <WorkflowTabs />
      <Outlet />
    </>
  );
}

export default WorkflowHeader;
