import React from 'react';
import { HitlStatus } from '../constants/constants';

export function isEmpty(obj = {}) {
  return Object.keys(obj).length === 0;
}

export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

export function isNumber(value) {
  return typeof value === 'number' && !Number.isNaN(value);
}

export function isBoolean(value) {
  return value === true || value === false;
}

export function isNil(value) {
  return typeof value === 'undefined' || value === null;
}

export function isDateString(value) {
  if (!isString(value)) return false;

  return value.match(/^\d{2}-\d{2}-\d{4}$/);
}

export function convertDateString(value) {
  return value.substr(6, 4) + value.substr(3, 2) + value.substr(0, 2);
}

export function toLower(value) {
  if (isString(value)) {
    return value.toLowerCase();
  }
  return value;
}

export function convertType(value) {
  if (isNumber(value)) {
    return value.toString();
  }

  if (isDateString(value)) {
    return convertDateString(value);
  }

  if (isBoolean(value)) {
    return value ? '1' : '-1';
  }

  return value;
}

export function titlecase(input) {
  return input?.replace(/\w\S*/g, ((txt) => txt[0].toUpperCase() + txt.substr(1).toLowerCase()));
}

export function removeUnderscore(input) {
  return input.replaceAll('_', ' ');
}

export function roundToTwo(num) {
  return +(`${Math.round(`${num}e+2`)}e-2`);
}

export function filterRows(rows, filters) {
  if (isEmpty(filters)) return rows;

  return rows.filter((row) => Object.keys(filters).every((accessor) => {
    const value = row[accessor];
    const searchValue = filters[accessor];

    if (isString(value)) {
      return toLower(value).includes(toLower(searchValue));
    }

    if (isBoolean(value)) {
      return (searchValue === 'true' && value) || (searchValue === 'false' && !value);
    }

    if (isNumber(value)) {
      return value === searchValue;
    }

    return false;
  }));
}

export function sortRows(rows, sortBy, orderBy) {
  if (orderBy === 'asc') {
    return rows.sort(
      (a, b) => 0 - (a[sortBy] > b[sortBy] ? -1 : 1),
    );
  }
  return rows.sort(
    (a, b) => 0 - (a[sortBy] > b[sortBy] ? 1 : -1),
  );
}

export function paginateRows(sortedRows, activePage, rowsPerPage) {
  return [...sortedRows].slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage);
}

export function formatNumberToPercentage(number) {
  return `${(number * 100).toFixed(2)}%`;
}

export function capitalizeFirstLetter(inputString) {
  if (!inputString) {
    return inputString;
  }

  const words = inputString.split(' ');
  const wordArr = words.map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase());
  return wordArr.join(' ');
}

export function cleanString(inputString) {
  if (!inputString) {
    return inputString;
  }
  const cleanedString = inputString.replace(/[^a-z0-9]+/gi, '');
  return cleanedString;
}

export function sanitizeString(inputString) {
  if (!inputString) {
    return inputString;
  }
  const cleanedString = inputString.replace(/[:"]/gi, '');
  return cleanedString.trimEnd();
}

export function omitProperties(obj, propertyList) {
  const updatedObj = { ...obj };
  propertyList.forEach((property) => {
    delete updatedObj[property];
  });

  return updatedObj;
}

export function validateDescription(value) {
  if (value?.trim()?.length === 0 || value?.length < 5 || value?.length > 200 || value.search(/^[a-zA-Z0-9: _-]+$/i) === -1) {
    return false;
  }
  return true;
}
export function validateNumber(value) {
  if (value.search(/^\d+$/) === -1 || value?.trim()?.length === 0) {
    return false;
  }
  return true;
}

export function formattedTime(totalSeconds) {
  const totalMs = totalSeconds * 1000;
  const result = new Date(totalMs).toISOString().slice(11, 19);
  const values = result.split(':');

  return values;
}

export function formattedReviewSeconds(totalSeconds, skipZeros = false) {
  const values = formattedTime(totalSeconds);

  const hours = +values[0];
  const minutes = +values[1];
  const secs = +values[2];

  let hoursTemp;
  let minutesTemp;
  let secsTemp;

  if (!skipZeros) {
    hoursTemp = `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
    minutesTemp = `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
    secsTemp = `${secs} ${secs === 1 ? 'sec' : 'secs'}`;
  } else {
    hoursTemp = hours !== 0 ? `${hours} ${hours === 1 ? 'hr' : 'hrs'}` : '';
    minutesTemp = minutes !== 0 ? `${minutes} ${minutes === 1 ? 'min' : 'mins'}` : '';
    secsTemp = `${secs} ${secs === 1 ? 'sec' : 'secs'}`;
  }

  return `${hoursTemp} ${minutesTemp} ${secsTemp}`;
}

export function hitlShouldShow(allowHitl) {
  if (allowHitl !== HitlStatus.HIDE) {
    return true;
  }
  return false;
}

export function hitlShouldEnable(allowHitl) {
  if (allowHitl === HitlStatus.ENABLE) {
    return true;
  }
  return false;
}

export function hitlShouldEnableOrHide(allowHitl) {
  if (allowHitl === HitlStatus.ENABLE || allowHitl === HitlStatus.HIDE) {
    return true;
  }
  return false;
}

export function hitlShouldDisable(allowHitl) {
  if (allowHitl === HitlStatus.DISABLE) {
    return true;
  }
  return false;
}

export function hitlShouldDisableOrHide(allowHitl) {
  if (allowHitl === HitlStatus.DISABLE || allowHitl === HitlStatus.HIDE) {
    return true;
  }
  return false;
}

export function emailValidation(email) {
  // another pattern -: /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
}

export function isSingleDigit(value) {
  const pattern = /^[0-9]{1}$/;
  return pattern.test(value);
}

// get date range as per specific timeframes
export function getDates(timeframe) {
  const currentDate = new Date();

  if (timeframe === 'today') {
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0,
      0,
    );
    const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
    return {
      startDate,
      endDate,
    };
  }
  if (timeframe === 'this_week') {
    const startDayOfWeek = 1;
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - ((currentDate.getDay() - startDayOfWeek + 7) % 7),
      0,
      0,
      0,
      0,
    );
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + ((startDayOfWeek + 6 - currentDate.getDay()) % 7),
      0,
      0,
      0,
      0,
    );
    endDate.setDate(endDate.getDate() + 1);
    return {
      startDate,
      endDate,
    };
  }
  if (timeframe === 'this_month') {
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1, 0, 0, 0, 0);
    return {
      startDate,
      endDate,
    };
  }
  return null;
}

export function addDays(muiDateObj, numberOfDays) {
  const tempObj = {
    date: muiDateObj,
  };

  const dateObj = new Date(tempObj.date);

  return new Date(dateObj.getTime() + 24 * numberOfDays * 60 * 60 * 1000);
}

export function dateTimeFormatter(timestamp) {
  if (!timestamp) {
    return ['-', '-'];
  }
  const date = new Date(timestamp).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });
  return date.split(', ');
}

export function timeDifference(timestamp1, timestamp2) {
  if (!timestamp1 || !timestamp2) return '-';

  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  let difference = date1.getTime() - date2.getTime();

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  const secondsDifference = Math.floor(difference / 1000);

  return ({
    daysDifference,
    hoursDifference,
    minutesDifference,
    secondsDifference,
  });
}

// default text length is 30
export function truncateText(text, len = 30) {
  return text.length > len ? `${text.slice(0, len)}...` : text;
}

// "Password should contain atleast 8 characters long,
// having both uppercase and lowercase letters,
// should contain atleast one digit and one special character"
export function validatePassword(password) {
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const digitRegex = /\d/;
  const specialCharacterRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

  // Check length
  if (password.length < 8) {
    return 'Password should be at least 8 characters long';
  }
  // Check for uppercase letter
  if (!uppercaseRegex.test(password)) {
    return 'Password should contain at least one uppercase letter';
  }
  // Check for lowercase letter
  if (!lowercaseRegex.test(password)) {
    return 'Password should contain at least one lowercase letter';
  }
  // Check for digit
  if (!digitRegex.test(password)) {
    return 'Password should contain at least one digit';
  }
  // Check for special character
  if (!specialCharacterRegex.test(password)) {
    return 'Password should contain at least one special character';
  }
  return '';
}

export function getFileName(docName = '') {
  const lastIndex = docName.lastIndexOf('.');

  if (lastIndex !== -1) {
    const fileNameOnly = docName.slice(0, lastIndex);
    return fileNameOnly;
  }
  return '';
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const isImage = (fileExt) => ['jpg', 'jpeg', 'png', 'svg'].includes(fileExt);

export const isPdf = (fileExt) => ['pdf'].includes(fileExt);

export const isExcel = (fileExt) => ['xls', 'xlsx', 'csv'].includes(fileExt);

export const isDoc = (fileExt) => ['doc', 'docx'].includes(fileExt);

export const compareArray = (arr1, arr2) => {
  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export function formatDate(date) {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');
  let hours = dateObj.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12;
  hours = hours.toString().padStart(2, '0');

  const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  const seconds = dateObj.getSeconds().toString().padStart(2, '0');

  return `${month}-${day}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
}

export function sortByKeyInOrder(arr, key, order) {
  return arr.sort((a, b) => {
    const indexOfA = order.findIndex((element) => element.localeCompare(a[key], undefined, { sensitivity: 'base' }) === 0);
    const indexOfB = order.findIndex((element) => element.localeCompare(b[key], undefined, { sensitivity: 'base' }) === 0);
    if (indexOfA === -1) return 1; // If not found in order, move to end
    if (indexOfB === -1) return -1; // If not found in order, move to beginning

    return indexOfA - indexOfB;
  });
}

export function isUatEnvironment() {
  if (process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === 'uat') return true;
  return false;
}

/**
 * Highlights specified patterns in the text using a CSS class.
 * @param {string} text - The text to be highlighted.
 * @param {string | RegExp} pattern - The pattern to highlight (a character like `#` or a regex).
 * @returns {JSX.Element} - A React component with specified patterns highlighted.
 */
export const highlightPattern = (text, pattern, color) => {
  // Ensure pattern is a regular expression, escape special characters if needed
  const regex = typeof pattern === 'string' ? new RegExp(`(${pattern}\\w*)`, 'g') : pattern;

  // Split the text using the pattern and include the pattern in the result
  const segments = text.split(regex).filter(Boolean);
  return (
    <span>
      {segments.map((segment, index) => (regex.test(segment) ? (
        <span key={`${index + 1}`} style={{ color }}>{segment}</span>
      ) : (
        <span key={`${index + 1}`}>{segment}</span>
      )))}
    </span>
  );
};

export function getFileExtension(filename) {
  if (typeof filename !== 'string') {
    throw new Error('Filename must be a string');
  }

  const parts = filename.split('.');

  // Check for edge cases
  if (parts.length < 2) {
    return ''; // No extension found
  }

  // Return the last part as the file extension
  return parts.pop().trim().toLowerCase();
}
