import { ApplicationActionTypes } from '../constants/applicationActionTypes';

export const setOpenedApplication = (application) => ({
  type: ApplicationActionTypes.SET_OPENED_APPLICATION,
  payload: application,
});

export const removeOpenedApplication = () => ({
  type: ApplicationActionTypes.REMOVE_OPENED_APPLICATION,
});

export const resetApplicationState = () => ({
  type: ApplicationActionTypes.RESET_APPLICATION,
});
