import { ReadOnlyMode, Permissions } from '../constants/constants';

function NavbarRoutes() {
  let navBarItems = [
    {
      tooltip: 'Application Dashboard',
      normalIcon: 'home',
      activeIcon: 'homeActive',
      width: 16,
      height: 16,
      href: '/home/application-dashboard',
      key: 'applicationDashboard',
      permissions: [],
    },
    {
      tooltip: 'Create Application',
      normalIcon: 'createApplication',
      activeIcon: 'createApplicationActive',
      width: 14,
      height: 18,
      href: '/home/create-application',
      key: 'createApplication',
      permissions: [Permissions.CREATE_APPLICATION],
    },
    {
      tooltip: 'Insights Dashboard',
      normalIcon: 'insightsDashboard',
      activeIcon: 'insightsDashboardActive',
      width: 14,
      height: 18,
      href: '/home/insights-dashboard',
      key: 'insightsDashboard',
      permissions: [Permissions.VIEW_METRICS],
    },
    {
      tooltip: 'Access Management',
      normalIcon: 'accessManagement',
      activeIcon: 'accessManagementActive',
      width: 19,
      height: 19,
      href: '/home/access-management',
      key: 'accessManagement',
      permissions: [Permissions.CREATE_ROLE, Permissions.CREATE_USER],
    },
  ];

  if (ReadOnlyMode === true) {
    navBarItems = navBarItems.filter((object) => object.label?.toLowerCase() !== 'file upload');
  }

  return navBarItems;
}

export default NavbarRoutes;
